<template>
    <div class="container">
        <div class="is-flex is-justify-content-center mb-2">
            <div v-for="slide in slides" :key="slide.id" @click="nextSlide(slide.id)">
                <div class="card pricing-rates business-rate shadow bg-light text-center border-0 mx-4 p-4">
                    <div v-if="slide.type == 'PLAN AMPLIO'" class="ribbon ribbon-right ribbon-warning overflow-hidden">
                        <span class="text-center d-block shadow small h6">
                            Catastrófico</span>
                    </div>
                    <h6 class="title font-weight-bold text-uppercase text-primary">
                        {{ slide.type }}
                    </h6>
                </div>
            </div>
        </div>
        <hr>
        <div class="carousel">
            <div class="slides" :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
                <div v-for="slide in slides" :key="slide.id" class="slide">
                    <h3 class="title font-weight-bold text-uppercase text-primary">
                        {{ slide.type }}
                    </h3>
                    <div class="container row">
                        <div v-for="(pack, idx) in slide.data.packages" :key="pack.id" class="col-6 mb-4">
                            <div class="card pricing-rates business-rate shadow bg-light rounded text-center border-0">
                                <!---->
                                <div v-if="slide.type == 'PLAN AMPLIO'"
                                    class="ribbon ribbon-right ribbon-warning overflow-hidden">
                                    <span class="text-center d-block shadow small h6">
                                        Catastrófico</span>
                                </div>
                                <div class="card-body py-5">
                                    <h6 class="title font-weight-bold text-uppercase text-primary mb-4">
                                        {{ pack.name }}: {{ formatterAmount(pack.secureAmount) }} MXN
                                    </h6>
                                    <div class="d-flex justify-content-center mb-4">
                                        <span class="price h3 mb-0">
                                            {{
                                            formatterAmount((pack.total / 12).toFixed(2))
                                        }}
                                        </span>
                                        <span class="h6 align-self-end mb-1 pl-1"> MXN/mes</span>
                                    </div>

                                    <ul v-if="slide.type == 'PLAN AMPLIO'" class="list-unstyled mb-0 pl-0">
                                        <li class="h6 text-muted mb-0">
                                            <span style="font-size: 20px">Incluye plan básico </span>
                                        </li>
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 mr-2">
                                                <i class="uil uil-check-circle align-middle"></i> </span>Terromoto y/o
                                            Erupción Volcánica
                                        </li>
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 mr-2">
                                                <i class="uil uil-check-circle align-middle"></i> </span>Riesgos
                                            hidrometeorológicos (Daños por lluvias)
                                        </li>
                                    </ul>

                                    <ul v-else v-for="idx in 3" :key="idx" class="list-unstyled mb-0 pl-0">
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 mr-2">
                                                <i class="uil uil-check-circle align-middle"></i> </span>
                                                {{ slide.data.features[idx].coverage }}
                                        </li>
                                    </ul>

                                    <br />

                                    <a href="javascript:void(0)" @click="$emit('showModal', {type: slide.type == 'PLAN AMPLIO' ? 'CAT' : 'NoCat', idx: idx})"><b>Ver cobertura
                                            +</b></a>
                                    <br />
                                    <a href="javascript:void(0)" class="btn btn-primary mt-4"
                                        @click="$emit('stepNext', {total: pack.total, type: slide.type == 'PLAN AMPLIO' ? 'CAT' : 'NoCat', packageId: pack.id })">Elegir plan</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, PropType } from 'vue'

const props = defineProps({
    // data: Object as PropType<slidePlan>
    data: Object
})

const currentSlide = ref(0)
const slides = computed(() => [
    // {
    //     id: 0,
    //     type: "PLAN BÁSICO",
    //     data: props.data?.NoCAT
    // },
    {
        id: 1,
        type: "PLAN AMPLIO",
        data: props.data?.CAT
    }
])

const nextSlide = (id) => {
    currentSlide.value = id;
}

const formatterAmount = (value) => {
    const change = new Intl.NumberFormat("es-MX", {
        currency: "MXN",
        maximumFractionDigits: 10,
      }).format(value)
      return `$ ${change}`;
    }
</script>

<style>
.is-flex {
    display: flex;
}

.is-justify-content-center {
    justify-content: center;
}

.carousel {
    overflow: hidden;
}

.slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.slide {
    flex: 0 0 100%;
}
</style>
